<template>
  <div>
    <router-link
      :to="{ name: 'payment.list', params: { appId: application.id } }"
      title="Afficher les détails"
    >
      <img :src="application.logo" class="img-fluid" alt="Logo" />
    </router-link>
    <div class="ux-designer">
      <div class="image-upload d-flex justify-content-center">
        <a
          class="btn btn-primary btn-icons btn-rounded shadow-2"
          href="javascript:void(0)"
        >
          <label :for="'file-' + application.id">
            <i v-if="!loadImage" class="fas fa-camera fa-2x p-2"></i>
            <i v-if="loadImage" class="fas fa-spinner fa-pulse p-2 fa-2x"></i>
          </label>
        </a>
        <input
          :id="'file-' + application.id"
          @change="onFileChange($event)"
          type="file"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ApplicationLogo",

  props: {
    application: { type: Object }
  },

  data() {
    return {
      loadImage: false
    };
  },

  methods: {
    onFileChange(event) {
      if (event.target.files && event.target.files.length > 0) {
        this.loadImage = true;
        let formData = new FormData();
        const file = event.target.files[0];
        formData.append("logo", file);
        this.$store
          .dispatch("payment/changeApplicationLogo", {
            appId: this.application.id,
            formData
          })
          .then(() => {
            this.loadImage = false;
          });
      }
    }
  }
};
</script>

<style>
.image-upload > input {
  display: none;
}

.image-upload {
  cursor: pointer;
  color: #03a9f4;
  z-index: 2000;
}
</style>
