export const clipboardMixin = {
  methods: {
    handleSuccess() {
      this.$store.dispatch("notification/add", {
        type: "info",
        message: "Copie effectuée avec succès",
        is_toast: false,
      });
    },

    handleError() {
      this.$store.dispatch("notification/add", {
        type: "danger",
        message: "Erreur de copie",
        is_toast: false,
      });
    },
  },
};
