<template>
  <div>
    <BaseLoader v-if="loading" />
    <template v-if="!loading">
      <template v-if="applications.length > 0">
        <ApplicationItem
          v-for="application in applications"
          :key="application.id"
          :application="application"
        />
        <BasePagination
          :current_page="app_current_page"
          :last_page="app_last_page"
          class="mt-4"
          @page="getApplications"
        />
      </template>
      <BaseEmptyList v-else />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ApplicationItem from "../../components/payment/ApplicationItem";

export default {
  name: "PaymentAppList",
  components: { ApplicationItem },
  data() {
    return {
      loading: true,
      applicationField: {
        users_id: null,
        paginate: 4,
      },
    };
  },

  computed: {
    ...mapState("payment", ["metaApplication"]),
    ...mapGetters("payment", [
      "applications",
      "app_current_page",
      "app_last_page",
    ]),
    ...mapGetters("auth", ["userId"]),
  },

  created() {
    this.applicationField.users_id = this.userId;
    this.getApplications(1);
  },

  methods: {
    getApplications(page) {
      this.loading = true;
      this.$store
        .dispatch("payment/getApplications", {
          page: page,
          field: this.applicationField,
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
