<template>
  <div class="card">
    <div class="card-header">
      <h5>
        <b>{{ application.label }}</b>
      </h5>
      <div class="card-header-right btn-group-sm col-auto m-r-10">
        <router-link
          :to="{ name: 'payment.editApp', params: { appId: application.id } }"
          class="btn btn-secondary"
          ><i class="feather icon-edit"></i> Modifier
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-10 text-center col-md-4 col-lg-3">
        <div class="card-body">
          <ApplicationLogo :application="application" />
          <router-link
            class="text-dark f-w-500"
            :to="{ name: 'payment.list', params: { appId: application.id } }"
            ><i class="feather icon-list"></i> Consulter les paiements
          </router-link>
        </div>
      </div>
      <div class="col-md-8 col-lg-9">
        <ul class="list-group p-0 shadow-sm">
          <li class="list-group-item">
            <div class="row pb-2">
              <div class="col-md-4 text-sm-left text-right mb-2 mb-sm-0">
                <i class="fas f-14 fa-info m-r-5" />
                Presentation
              </div>
              <div class="col-md-8 text-right">
                <small class="f-w-500">{{ application.description }}</small>
              </div>
            </div>
            <div class="row border-top pt-2 pb-2">
              <div class="col-md-4 text-sm-left text-right  mb-2 mb-sm-0">
                <i class="fas f-14 fa-check-circle m-r-5" />
                Application ID
              </div>
              <div class="col-md-8 text-right">
                <a
                  href="#"
                  @click.prevent=""
                  title="Copier l'ID de l'application"
                  v-clipboard="application.application_id"
                  @success="handleSuccess"
                  @error="handleError"
                  class="mr-2"
                  ><i class="feather icon-copy f-16"></i
                ></a>
                <small class="f-w-500">{{ application.application_id }}</small>
              </div>
            </div>
            <div class="row border-top pt-2 pb-2">
              <div class="col-md-4 text-sm-left text-right mb-2 mb-sm-0">
                <i class="fas f-14 fa-key m-r-5" />
                Application Secret
              </div>
              <div class="col-md-8 text-right">
                <a
                  href="#"
                  @click.prevent=""
                  title="Copier l'ID de l'application"
                  v-clipboard="application.application_secret"
                  @success="handleSuccess"
                  @error="handleError"
                  class="mr-2"
                  ><i class="feather icon-copy f-16"></i
                ></a>
                <small class="f-w-500">{{
                  application.application_secret
                }}</small>
              </div>
            </div>
            <div class="row border-top pt-2 pb-2">
              <div class="col-md-4 text-sm-left text-right mb-2 mb-sm-0">
                <i class="fas f-14 fa-link m-r-5" />
                Callback
              </div>
              <div class="col-md-8 text-right">
                <a
                  href="#"
                  @click.prevent=""
                  title="Copier l'ID de l'application"
                  v-clipboard="application.application_url"
                  @success="handleSuccess"
                  @error="handleError"
                  class="mr-2"
                  ><i class="feather icon-copy f-16"></i
                ></a>
                <small class="f-w-500 text-info">{{
                  application.application_url
                }}</small>
              </div>
            </div>
            <div class="row border-top pt-2 pb-2">
              <div class="col-md-4 text-sm-left text-right mb-2 mb-sm-0">
                <i class="fas f-14 fa-link m-r-5" />
                Redirect Success
              </div>
              <div class="col-md-8 text-right">
                <a
                  href="#"
                  @click.prevent=""
                  title="Copier l'ID de l'application"
                  v-clipboard="application.success_url"
                  @success="handleSuccess"
                  @error="handleError"
                  class="mr-2"
                  ><i class="feather icon-copy f-16"></i
                ></a>
                <small class="f-w-500">{{ application.success_url }}</small>
              </div>
            </div>
            <div class="row border-top pt-2 pb-2">
              <div class="col-md-4 text-sm-left text-right mb-2 mb-sm-0">
                <i class="fas f-14 fa-link m-r-5" />
                Redirect Cancel/Fail
              </div>
              <div class="col-md-8 text-right">
                <a
                  href="#"
                  @click.prevent=""
                  title="Copier l'ID de l'application"
                  v-clipboard="application.cancel_url"
                  @success="handleSuccess"
                  @error="handleError"
                  class="mr-2"
                  ><i class="feather icon-copy f-16"></i
                ></a>
                <small class="f-w-500">{{ application.cancel_url }}</small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { clipboardMixin } from "../../mixins/clipboardMixin";
import ApplicationLogo from "./ApplicationLogo";

export default {
  name: "ApplicationItem",
  components: { ApplicationLogo },
  mixins: [clipboardMixin],
  props: {
    application: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped></style>
